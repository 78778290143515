import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ["mistake_to_add", "critical"]
  connect() {
    console.log("hiu")
  }
  associate() {
    console.log(this.criticalTarget)
    $.ajax(
      {
        url: "/mistakes/associate",
        data: JSON.stringify({ game_id: this.data.get("gameid"), mistake_id: this.mistake_to_addTarget.value, critical: this.criticalTarget.checked }),
        contentType: "application/json",
        method: "post",
        dataType: "script"
      }
    )
  }
}
