import Chess from 'chess.js'

class Board {
  constructor(opts) {
    this.game = new Chess()
    this.opts = {}
    if (opts) {
      this.opts = opts
    }
  }

  lock() {
    this.locked = true
  }

  unlock() {
    this.locked = false
  }

  selectSquare(sq) {
    if (this.locked) {
      return
    }
    this.unhighlight()
    this._currentSquare = sq
    $('.square-'+sq).addClass('sq-selected');
  }

  setOnMove(fn) {
    this.opts.onMove = fn
  }

  currentSquare() {
    return this._currentSquare
  }

  tryMove(from, to) {
    if (this.locked) {
      return
    }
    this.unhighlight()
    var moveOpts = {
      from: from,
      to: to,
      promotion: 'q'
    }

    var move = this.game.move(moveOpts)
    this._currentSquare = null
    if (move == null) {
      return
    }
    this.position(this.game.fen())
    if (this.opts.onMove != null) {
      var hist = this.game.history()
      moveOpts.san = hist[hist.length-1]
      moveOpts.fen = this.game.fen()
      this.opts.onMove(moveOpts)
    }
  }

  setClickHandlers() {
    var self = this
    $('[class^=square-]').off('click')
    $('[class^=square-]').on('click', (el) => {
      var s = $(el.currentTarget)
      var classes = s.attr('class').split(' ')
      var sq = classes[classes.length-1].split('-').splice(1)[0]
      var piece = self.game.get(sq)
      var curSq = self.currentSquare()
      if (piece != null || curSq) {
        self.selectSquare(sq)
      }
      if (curSq) {
        self.tryMove(curSq, sq)
      }
    })
  }

  unhighlight() {
    $('[class^=square-]').removeClass('sq-selected');
  }
  
  setPos(fen) {
    this.position(fen)
    this.game.load(fen)
    this.setClickHandlers()
  }

  init() {
    this.setClickHandlers()
  }
}
function NewBoard(cb) {
  var newObj = Object.create(new Board())
  Object.assign(newObj, cb)
  newObj.init()
  return newObj
}

export { NewBoard }
