import Chess from 'chess.js'

class Analysis {
  constructor() {
    var wasmSupported = typeof WebAssembly === 'object' && WebAssembly.validate(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
    this.engine = new Worker(wasmSupported ? '/sf/stockfish.wasm.js' : '/sf/stockfish.js');
  }
  eval(game, opts) {
    if (this.engine == null) {
      setTimeout(1000, () => { eval(game, cb, opts) })
      return
    }
    var self = this
    if (this.prevListener) {
      this.engine.removeEventListener('message', this.prevListener)
    }
    this.engine.postMessage("stop")
    this.engine.postMessage("position fen "+game.fen())

    var args = "infinite"

    if (opts.depth != null) {
      args = "depth "+opts.depth
    }
    if (opts.moveTime != null) {
      args = "movetime "+opts.moveTime
    }
    this.engine.postMessage("go "+args)
    var listener = line => {
      self.runCallback(line.data, game, opts)
    }
    this.engine.addEventListener('message', listener)
    this.prevListener = listener
  }

  stop() {
    this.engine.postMessage("stop")
  }

  runCallback(line, game, opts) {
    var cb = opts.callback
    if (cb == null) {
      return
    }
    var fen = game.fen()
    var rxUCI = /depth (\d+).*score cp (\-?\d+).*pv (.*)/
    var rxMultiPV = /multipv (\d+)/
    var move_num = opts.moveNum ? opts.moveNum : parseInt(game.history().length) + 1
    var uci_out = line
    var matches = uci_out.match(rxUCI)
    if (!matches) {
      return
    }
    var pvMatch = uci_out.match(rxMultiPV)

    var depth = matches[1]
    var score = matches[2] / 100
    var pv = pvMatch[1]
    var moves = matches[3].split(' ')
    var all_moves = moves.slice()
    var sign = score >= 0 ? "+" : ""
    var chess = new Chess(fen);
    var line_output = ""
    var move_line = Math.round(move_num / 2)
    if (move_num % 2 == 0) {
      var next_move = moves.shift()
      var real_move = chess.move(next_move, {sloppy: true})
      if (real_move == null) {
        return
      }
      line_output += `${move_line}...${real_move.san} `
      move_num++
    }

    var move;
    while (move = moves.shift()) {
      if (move == null) {
        break
      }
      var real_move = chess.move(move, {sloppy: true})
      var move_line = Math.round(move_num / 2)
      if (move_num % 2 == 1) {
        line_output += `${move_line}.${real_move.san} `
      } else {
        line_output += `${real_move.san} `
      }
      move_num++
    }
    cb({
        sign: sign,
        score: score,
        moves: line_output,
        raw_moves: all_moves,
        depth: depth,
    })
  }
}

export { Analysis }
