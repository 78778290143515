import { Controller } from 'stimulus'
import Chess from 'chess.js'
import Siema from 'siema'
import { NewBoard } from '../chessdog/board.js'
import { Analysis } from '../chessdog/sf.js'

var maxDepth = 16;

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this
    var cb = null;
    var self = this;
    $(window).on('load', function() {
      self.cb = NewBoard(Chessboard('mainboard', {position: 'empty', showNotation: true, pieceTheme: '/img/chesspieces/wikipedia/{piece}.png'}))
      self.getNextBlunder()
    })
    const chess = new Chess()
    this.chess = chess
    this.analysis = new Analysis()
    this.currentBlunder = {}
  }

  moveTo(fen) {
    this.cb.setPos(fen)
    this.chess.load(fen)
  }

  startTimer() {
    var initialTime = $('#stopwatch').html()
    var parts = initialTime.split(':')
    this.hours = parseInt(parts[0])
    this.minutes = parseInt(parts[1])
    this.seconds = parseInt(parts[2])
    var self = this
    setInterval(this.tickTimer.bind(self), 1000)
  }

  tickTimer() {
    this.seconds++;
    if (this.seconds >= 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes >= 60) {
            this.minutes = 0;
            this.hours++;
        }
    }
    
    $('#stopwatch').html((this.hours ? (this.hours > 9 ? this.hours : "0" + this.hours) : "00") + ":" + (this.minutes ? (this.minutes > 9 ? this.minutes : "0" + this.minutes) : "00") + ":" + (this.seconds > 9 ? this.seconds : "0" + this.seconds))
  }

  setBlunder(data) {
    this.cb.orientation(data.side)
    this.cb.setPos(data.fen)
    this.chess.load(data.fen)
    this.currentBlunder = {
      id: data.id,
      fen: data.fen,
      bestMove: data.best_move,
      moveNum: data.move_num,
      actualMove: data.actual_move,
      result: 'not_attempted',
    }

    // evaluate the best move
    var bmChess = new Chess(this.chess.fen())
    var move = bmChess.move(this.currentBlunder.bestMove, {sloppy: true})
    this.cb.lock()
    this.analysis.eval(bmChess, {moveNum: this.currentBlunder.moveNum, callback: this.evalBlunderBestMove.bind(this), depth: maxDepth})
    $('#blunder-attempt-best-move').html(`(${this.currentBlunder.bestMove})`);
    this.cb.setOnMove(this.attemptBlunder.bind(this))
    $('#blunders-attempts-table').empty()
    $('#blunder-attempt-move').html(this.currentBlunder.actualMove)
  }

  getNextBlunder() {
    var self = this
    $.get('/tactics/next', function() { self.startTimer() })
  }

  revealToggle() {
    $('#blunder-attempt-best-move').toggleClass('d-none')
  }

  evalBlunderBestMove(evalInfo) {
    this.currentBlunder.bestMoveScore = evalInfo.score * -1
    if (evalInfo.depth == maxDepth) {
      this.cb.unlock()
    }
    $('#blunder-attempt-best-score').removeClass('move-eval-black move-eval-white');
    var newScore = (this.currentBlunder.moveNum % 2 == 1) ? (evalInfo.score * -1) : evalInfo.score
    var scoreClass = (newScore < 0) ? 'move-eval-black' : 'move-eval-white'
    $('#blunder-attempt-best-score').addClass(scoreClass).html(newScore);
  }

  addBlunderEval(evalInfo) {
    var scoreDiff = this.currentBlunder.bestMoveScore - (evalInfo.score * -1)
    scoreDiff = Math.round(scoreDiff * 100) / 100
    var newScore = (this.currentBlunder.moveNum % 2 == 1) ? (evalInfo.score * -1) : evalInfo.score
    // add evaluation and pv to table
    // depth maxDepth means we're finished
    if (evalInfo.depth == maxDepth && this.blunderAttempted) {
      this.blunderAttempted = false
      var tr = $('#blunders-attempts-table tr:last-child')
      var scoreClass = (newScore < 0) ? 'move-eval-black' : 'move-eval-white'
      var sign = (newScore > 0) ? '+' : ''
      tr.children('td.attempt-score').first().html(`<span class="${scoreClass}">${sign}${newScore}</span>`)
      tr.children('td.attempt-pv').first().html(evalInfo.moves)
      var attemptClass = "ok"

      var thisMove = tr.data('move')
      if (thisMove == this.currentBlunder.bestMove) {
        tr.find('td.attempt-move span').append('!!')
        scoreDiff = 0
        attemptClass = "best"
      } else if (scoreDiff > 3) {
        tr.find('td.attempt-move span').append('??')
        attemptClass = "blunder";
      } else if (scoreDiff > 1) {
        tr.find('td.attempt-move span').append('?')
        attemptClass = "mistake";
      } else if (scoreDiff > 0.3) {
        attemptClass = "ok"
      } else if (scoreDiff > 0.1) {
        tr.find('td.attempt-move span').append('!')
        attemptClass = "good"
      } else {
        tr.find('td.attempt-move span').append('!!')
        attemptClass = "good"
      }
      if (this.currentBlunder.result == 'not_attempted') {
        if (scoreDiff == 0) {
          this.currentBlunder.result = 'best'
        } else if (scoreDiff > 1) {
          this.currentBlunder.result = 'failed'
        } else {
          this.currentBlunder.result = 'passed'
        }
        $('#result').val(this.currentBlunder.result)
      }
      tr.find('td.attempt-move span.attempt-span').addClass(`try ${attemptClass}`)

      this.moveTo(this.currentBlunder.fen)
      this.cb.unlock()
			$('#submit-button').removeClass('d-none')
			$('#reveal-button').removeClass('d-none')
      this.analysis.stop()
    }
  }
  reveal() {
    var tryChess = new Chess(this.currentBlunder.fen)
    tryChess.move(this.currentBlunder.bestMove, {sloppy: true})
    var hist = tryChess.history({ verbose: true })
    console.log(hist)
    var lastMove = hist[hist.length - 1]
    var moveInfo = {
      fen: tryChess.fen(),
      from: lastMove.from,
      to: lastMove.to,
      san: lastMove.san
    }
    this.attemptBlunder(moveInfo)
  }

  attemptBlunder(moveInfo) {
    var tryChess = new Chess(moveInfo.fen)
    this.cb.lock()
    this.blunderAttempted = true
    this.analysis.eval(tryChess, {moveNum: this.currentBlunder.moveNum+1, callback: this.addBlunderEval.bind(this), depth: maxDepth})
    $('#blunder-attempt-move').html(this.currentBlunder.actualMove)
    // update the attempt in the table
    $('#blunders-attempts-table').append(`<tr data-move="${moveInfo.from}${moveInfo.to}">
      <td class="attempt-move"><span class="attempt-span">${~~((this.currentBlunder.moveNum+1)/2)}. ${moveInfo.san}</span></td>
      <td class="attempt-icon"></td>
      <td class="attempt-score"></td>
      <td class="attempt-pv text-monospace"></td>
      </tr>`)
    $('#make-move').hide()
  }
}
