import { Controller } from 'stimulus'
import Chess from 'chess.js'
import { NewBoard } from '../chessdog/board.js'

export default class extends Controller {
  static targets = ["bookID"]

  connect() {
    this.element[this.identifier] = this
    var cb = null;
    var self = this;
    $(window).on('load', function() {
      self.cb = NewBoard(Chessboard('mainboard', {position: 'empty', orientation: self.data.get('orientation'), showNotation: true, pieceTheme: '/img/chesspieces/wikipedia/{piece}.png'}))
      self.getMoves()
    })
    const chess = new Chess()
    this.chess = chess
    this.history = []
  }

  moveTo(fen) {
    this.history.push(this.chess.fen())
    this.cb.setPos(fen)
    this.chess.load(fen)
  }

  back() {
    var fen = this.history.pop()
    if (fen == null) {
      return
    }
    this.cb.setPos(fen)
    this.chess.load(fen)
    this.getMoves(true)
  }

  getMoves(isBack) {
    $.get('/explorer/moves', { fen: this.chess.fen(), book_id: this.data.get('book-id'), isBack: isBack })
    // update pos
  }

  loadBook() {
    var bookID = this.bookIDTarget.value
    console.log(bookID)
    window.location.href = `/explorer?book_id=${bookID}`
  }
}
