import { Controller } from 'stimulus'
import Chess from 'chess.js'
import Siema from 'siema'
import { NewBoard } from '../chessdog/board.js'
import { Analysis } from '../chessdog/sf.js'

var maxDepth = 16

export default class extends Controller {
  connect() {
    var cb = null;
    var self = this;
    $(window).on('load', function() {
      console.log(self.data.get("orientation"))
      self.cb = NewBoard(Chessboard('mainboard', {position: 'start', orientation: self.data.get("orientation"), showNotation: true, pieceTheme: '/img/chesspieces/wikipedia/{piece}.png'}))
      self.cb.setPos(self.cb.fen())
      self.siema = new Siema()
      self.moveTo(-1)
      if (self.data.get("blunder-id") != null) {
        self.setBlunderFromID(self.data.get("blunder-id"))
      }
    })
    const chess = new Chess()
    this.chess = chess
    chess.load_pgn(this.data.get("pgn"))
    this.allMoves = chess.history()
    chess.reset()
    this.analysis = new Analysis()
    this.currentBlunder = {}
  }
  moveToIndex(_event, element) {
    var idx = _event.target.dataset.moveindex
    this.moveTo(idx)
  }

  moveTo(idx) {
    this.analysis.stop()
    this.chess.reset()
    for (var i = 0; i <= idx; i++) {
      this.chess.move(this.allMoves[i])
    }
    this.cb.setPos(this.chess.fen())
    this.data.set("moveIndex", idx)
    $('.notation a').removeClass('active')
    $('#move-'+idx).addClass('active')
    const cb = (evalInfo) => {
      // invert score if black to move
      var newScore = (idx % 2 == 0) ? (evalInfo.score * -1) : evalInfo.score
      if (newScore > 0) {
        newScore = '+' + newScore
        $('#score').removeClass('move-eval-black').addClass('move-eval-white')
      } else {
        $('#score').removeClass('move-eval-white').addClass('move-eval-black')
      }
      $('#score').html(newScore + ' ' + evalInfo.moves)
    }
    this.analysis.eval(this.chess, {moveTime: 5000, callback: cb})
  }

  moveFirst() {
    this.moveTo(-1)
  }

  moveForward() {
    this.moveTo(parseInt(this.data.get("moveIndex"))+1)
  }

  moveBack() {
    var moveIdx = parseInt(this.data.get("moveIndex"))
    if (moveIdx == 0) {
      return
    }
    this.moveTo(parseInt(this.data.get("moveIndex"))-1)
  }

  moveLast() {
    this.moveTo(parseInt(this.allMoves.length)-1)
  }

  eventSetBlunder(event) {
    var blunderID = event.currentTarget.dataset.blunderid
    this.setBlunderFromID(blunderID)
  }

  blundersBack() {
    //this.siema.goTo(0)
  }

  revealToggle() {
    $('#blunder-attempt-best-move').toggleClass('d-none')
  }

  setBlunderFromID(blunderID) {
    var blunderRow  = $('#blunder-'+blunderID)
    var fen = blunderRow.data('fen')
    var self = this
    this.analysis.stop()
    this.chess.load(fen)
    this.cb.setPos(this.chess.fen())
    $('.blunders-table tr').removeClass('table-active')
    blunderRow.addClass('table-active')
    //this.siema.goTo(1)
    this.currentBlunder = {
      id: blunderRow.data('blunderid'),
      fen: blunderRow.data('fen'),
      bestMove: blunderRow.data('bestmove'),
      moveNum: blunderRow.data('movenum'),
      actualMove: blunderRow.data('move'),
      starClass: blunderRow.data('starclass')
    }

    // evaluate the best move
    var bmChess = new Chess(this.chess.fen())
    var move = bmChess.move(this.currentBlunder.bestMove, {sloppy: true})
    this.cb.lock()
    this.analysis.eval(bmChess, {moveNum: this.currentBlunder.moveNum, callback: this.evalBlunderBestMove.bind(this), depth: maxDepth})
    $('#blunders-page2 h5 a').remove()
    $('#blunders-page2 h5').prepend(`<a href="/blunders/star/${this.currentBlunder.id}" data-remote="true"><i id="blunder-star-${blunderID}" class="${this.currentBlunder.starClass}"></i></a> `)
    $('#blunder-attempt-best-move').html(`(${this.currentBlunder.bestMove})`);
    this.cb.setOnMove(this.attemptBlunder.bind(this))
    $('#blunders-attempts-table').empty()
    $('#blunder-attempt-move').html(this.currentBlunder.actualMove)
  }

  evalBlunderBestMove(evalInfo) {
    this.currentBlunder.bestMoveScore = evalInfo.score * -1
    if (evalInfo.depth == maxDepth) {
      this.cb.unlock()
    }
    $('#blunder-attempt-best-score').removeClass('move-eval-black move-eval-white');
    var newScore = (this.currentBlunder.moveNum % 2 == 1) ? (evalInfo.score * -1) : evalInfo.score
    var scoreClass = (newScore < 0) ? 'move-eval-black' : 'move-eval-white'
    $('#blunder-attempt-best-score').addClass(scoreClass).html(newScore);
  }

  addBlunderEval(evalInfo) {
    var scoreDiff = this.currentBlunder.bestMoveScore - (evalInfo.score * -1)
    scoreDiff = Math.round(scoreDiff * 100) / 100
    console.log(`eval score ${evalInfo.score} best move ${this.currentBlunder.bestMoveScore} diff ${scoreDiff}`)
    var newScore = (this.currentBlunder.moveNum % 2 == 1) ? (evalInfo.score * -1) : evalInfo.score
    // add evaluation and pv to table
    // depth maxDepth means we're finished
    if (evalInfo.depth == maxDepth) {
      var tr = $('#blunders-attempts-table tr:last-child')
      var scoreClass = (newScore < 0) ? 'move-eval-black' : 'move-eval-white'
      var sign = (newScore > 0) ? '+' : ''
      tr.children('td.attempt-score').first().html(`<span class="${scoreClass}">${sign}${newScore}</span>`)
      tr.children('td.attempt-pv').first().html(evalInfo.moves)
      var attemptClass = "good"
      if (scoreDiff > 3) {
        tr.find('td.attempt-move span').append('??')
        attemptClass = "blunder";
      } else if (scoreDiff > 1) {
        tr.find('td.attempt-move span').append('?')
        attemptClass = "mistake";
      } else if (scoreDiff > 0.2) {
      } else if (scoreDiff > 0.1) {
        tr.find('td.attempt-move span').append('!')
      } else {
        tr.find('td.attempt-move span').append('!!')
      }
      tr.find('td.attempt-move span.attempt-span').addClass(`try ${attemptClass}`)

      this.moveTo(this.currentBlunder.moveNum-2)
      this.cb.unlock()
    }
  }

  attemptBlunder(moveInfo) {
    var tryChess = new Chess(moveInfo.fen)
    this.cb.lock()
    this.analysis.eval(tryChess, {moveNum: this.currentBlunder.moveNum+1, callback: this.addBlunderEval.bind(this), depth: maxDepth})
    $('#blunder-attempt-move').html(this.currentBlunder.actualMove)
    // update the attempt in the table
    $('#blunders-attempts-table').append(`<tr>
      <td class="attempt-move"><span class="attempt-span">${~~((this.currentBlunder.moveNum+2)/2)}. ${moveInfo.san}</span></td>
      <td class="attempt-icon"></td>
      <td class="attempt-score"></td>
      <td class="attempt-pv text-monospace"></td>
      </tr>`)
  }

  gptAnalyze() {
    var self = this
    const fen = encodeURIComponent(this.chess.fen());
    const gameId = this.data.get("gameId");
    // convert moveIndex to integer and add 1
    const nextMoveIdx = parseInt(this.data.get("moveIndex"))+1;
    const url = `/game/${gameId}/chatgpt_analysis?fen=${fen}&move_played=${this.allMoves[nextMoveIdx]}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      document.getElementById('gpt-analysis').innerHTML = data.chatgpt_response;
    })
    .catch(error => {
      console.error('Error fetching GPT analysis:', error);
    });
  }
}
